export default [
  {
    id: 'e8',
    label: 'E8',
    children: [
      {
        id: 'e8phase1',
        label: 'Phase One',
      },
      {
        id: 'e8phase2',
        label: 'Phase Two',
      },
      {
        id: 'e8funded',
        label: 'Funded',
      },
    ],
  }, {
    id: 'e8track',
    label: 'E8 Track',
    children: [
      {
        id: 'e8trackphase1',
        label: 'Phase One',
      },
      {
        id: 'e8trackphase2',
        label: 'Phase Two',
      },
      {
        id: 'e8trackphase3',
        label: 'Phase Three',
      },
      {
        id: 'e8trackfunded',
        label: 'Funded',
      },
    ],
  }, {
    id: 'e8trial',
    label: 'E8 Trial',
    children: [
      {
        id: 'e8trialtrial',
        label: 'Trial',
      },
    ],
  }, {
    id: 'elev8',
    label: 'ELEV8',
    children: [
      {
        id: 'elev8phase1',
        label: 'Phase One',
      },
      {
        id: 'elev8phase2',
        label: 'Phase Two',
      },
      {
        id: 'elev8funded',
        label: 'Funded',
      },
    ],
  },
  {
    id: 'e8tournament',
    label: 'E8 Tournament',
    children: [
      {
        id: 'e8tournamenttickets',
        label: 'Ticket',
      },
    ],
  },
]
