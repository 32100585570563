<template>
  <b-card>
    <b-card-header>
      <b-card-title>Spend vs withdraw per country</b-card-title>
      <b-form-group class="my-1 mr-1 ml-auto">
        <h5>Search by country</h5>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Search by country"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group class="mt-1">
        <h5>Date range</h5>
        <div class="d-flex">
          <flat-pickr
            v-model="dateRange"
            class="form-control"
            :config="pickrConfig"
          />

          <b-button
            variant="outline-primary"
            :disabled="dateRange == null"
            @click.prevent="onSearch"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
      </b-form-group>
    </b-card-header>
    <b-card-body>
      <b-table
        :items="items"
        :fields="fields"
        striped
        responsive
        small
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isLoading"
        :sort-by="'total_registered'"
        :sort-desc="true"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <table-loading />
        </template>

        <template #cell(country)="data">
          {{ data.item.country }}

          <b-button
            v-if="data.item.country_id"
            variant="outline-primary"
            class="btn-icon float-right mb-auto mr-2"
            @click="onSelectCountryFunded(data.item.country_id, data.item.country)"
          >
            <feather-icon icon="BarChartIcon" />
          </b-button>
          <b-button
            v-if="data.item.country_id"
            variant="outline-primary"
            class="btn-icon float-right mb-auto mr-2"
            @click="onSelectCountryWithdrawal(data.item.country_id, data.item.country)"
          >
            <feather-icon icon="DollarSignIcon" />
          </b-button>
        </template>

        <template #cell(spend_sum)="data">
          {{ formatCurrency(data.item.spend_sum, 'USD') }}
        </template>

        <template #cell(withdraw_sum)="data">
          {{ formatCurrency(data.item.withdraw_sum, 'USD') }}
        </template>

        <template #cell(profit)="data">
          {{ formatCurrency(data.item.profit, 'USD') }}
        </template>

        <template #cell(rel_spend)="data">
          {{ formatFloat(data.item.rel_spend * 100) }}%
        </template>

        <template #cell(rel_withdraw)="data">
          {{ formatFloat(data.item.rel_withdraw * 100) }}%
        </template>

        <template #cell(rel_spend_-_withdraw)="data">
          {{ formatFloat((data.item['rel_spend_-_withdraw']) * 100) }}%
        </template>

        <template #cell(pass_rates)="data">
          <b-overlay :show="data.item.loadingPassRates">
            <b-button
              v-if="!data.item.frLoaded"
              class="my-1 ml-auto mr-1"
              variant="outline-primary"
              @click="onGetPassRates(data.item)"
            >
              Show pass rates
            </b-button>
            <div v-if="typeof data.item.passRates !== 'undefined'">
              <span><strong>FR 2Phase: </strong>{{ formatFloat(data.item.passRates.fr2) }}%</span>
              <span class="ml-1"><strong>FR 3Phase: </strong>{{ formatFloat(data.item.passRates.fr3) }}%</span>
              <span
                v-for="(pr, key) in data.item.passRates.pr"
                :key="key"
                class="ml-1"
              ><strong>{{ key.toUpperCase().replace('_', ' ') }}: </strong>{{ formatFloat(pr) }}%</span>
            </div>
          </b-overlay>
        </template>

      </b-table>
      <pagination-row />
    </b-card-body>
    <app-collapse
      type="border"
      class="mb-1"
    >
      <app-collapse-item
        :title="countryNameForWithdrawals ? `Withdrawals - ${countryNameForWithdrawals}` : 'Withdrawals'"
        :is-visible="true"
      >
        <withdrawals-table
          v-if="withdrawalTableFilter.country !== null"
          :withdrawals-filter="withdrawalTableFilter"
        />
      </app-collapse-item>
      <app-collapse-item
        :title="countryNameForAccounts ? `Funded accounts - ${countryNameForAccounts}` : 'Funded accounts'"
        :is-visible="true"
      >
        <accounts-data-table
          v-if="accountsFilter.country !== null"
          :default-filter="accountsFilter"
          :show-advance-filters="false"
        />
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BButton, BFormGroup, BTable,
  BInputGroup, BFormInput, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { get } from 'vuex-pathify'
import table from '@/mixins/table'
import StatisticsAPI from '@/api/statistics'
import { formatFloat, formatCurrency } from '@/plugins/formaters'
import WithdrawalsTable from '@/views/statistics/fin-statistics/componenets/WithdrawalsTable.vue'
import AccountsDataTable from '@/components/page/accounts/AccountsDataTable.vue'
import { SPEND_VS_WITHDRAW_ACCOUNTS_FILTER } from '@/enums/accounts'

export default {
  components: {
    AccountsDataTable,
    WithdrawalsTable,
    BOverlay,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
  },
  mixins: [table],
  data() {
    return {
      fields: [
        {
          key: 'country',
          sortable: true,
        },
        {
          key: 'spend_sum',
          sortable: true,
        },
        {
          key: 'withdraw_sum',
          sortable: true,
        },
        {
          key: 'profit',
          sortable: true,
        },
        {
          key: 'rel_spend',
          sortable: true,
        },
        {
          key: 'rel_withdraw',
          sortable: true,
        },
        {
          key: 'rel_spend_-_withdraw',
          sortable: true,
        },
        {
          key: 'pass_rates',
          sortable: false,
        },
      ],
      dateRange: null,
      items: [],
      isLoading: false,
      filterOn: ['country'],
      filter: null,
      countryNameForWithdrawals: null,
      countryNameForAccounts: null,
      withdrawalTableFilter: {
        search: '',
        country: null,
        from: null,
        to: null,
      },
      accountsFilter: {
        account_type: SPEND_VS_WITHDRAW_ACCOUNTS_FILTER,
        search: '',
        country: null,
        from: null,
        to: null,
      },
    }
  },
  computed: {
    pickrConfig() {
      if (!this.me.roles.includes('ROLE_ADMIN_EXTERNAL')) return { wrap: true, mode: 'range', dateFormat: 'Y-m-d' }

      const back4M = new Date()
      back4M.setDate(back4M.getDate() - (28 * 4))

      return {
        wrap: true, mode: 'range', dateFormat: 'Y-m-d', minDate: back4M,
      }
    },
    ...get('auth', ['me']),
  },
  watch: {
    items() {
      this.totalRows = this.items.length
    },
  },
  created() {
    this.totalRows = this.items.length
  },
  beforeMount() {
    const now = new Date()
    const back7 = new Date()

    back7.setDate(back7.getDate() - 30)

    this.dateRange = `${back7.toISOString().slice(0, 10)} to ${now.toISOString().slice(0, 10)}`
    this.onSearch()
  },
  methods: {
    formatFloat,
    formatCurrency,
    onSelect() {
      if (this.dateRange === null) return

      this.onSearch()
    },
    onSelectCountryWithdrawal(countryId, name) {
      this.withdrawalTableFilter.country = countryId
      this.countryNameForWithdrawals = name
    },
    onSelectCountryFunded(countryId, name) {
      this.accountsFilter.country = countryId
      this.countryNameForAccounts = name
    },
    onSearch() {
      this.isLoading = true
      const date = this.dateRange.split(' to ')
      const from = date[0].concat(' 00:00:00')
      const to = date[1].concat(' 23:59:59')

      this.withdrawalTableFilter.from = from
      this.withdrawalTableFilter.to = to

      this.accountsFilter.from = from
      this.accountsFilter.to = to

      StatisticsAPI.getSpendVsWithdrawByCountry(from, to)
        .then(response => {
          this.items = response.map(item => ({
            ...item, profit: Number(item.profit), 'rel_spend_-_withdraw': (item.rel_spend - item.rel_withdraw), loadingPassRates: false, frLoaded: false,
          }))
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onGetPassRates(i) {
      const loadings = this.items.filter(item => item.loadingPassRates)
      if (loadings.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Only one pass rate can be requested at the time.',
            icon: 'SendIcon',
            variant: 'danger',
          },
        })
        return
      }

      const date = this.dateRange.split(' to ')
      const from = date[0].concat(' 00:00:00')
      const to = date[1].concat(' 23:59:59')
      i.loadingPassRates = true
      StatisticsAPI.getPassRatesByCountry(from, to, i.country_id).then(response => {
        i.passRates = {
          fr2: response.fr_two_phase,
          fr3: response.fr_three_phase,
          pr: _.flow([
            Object.entries,
            arr => arr.filter(([, value]) => value > 0),
            Object.fromEntries,
          ])(response.pass_rates),
        }
        i.frLoaded = true
      }).finally(() => {
        i.loadingPassRates = false
      })
    },
  },
}
</script>
<style lang="scss">
@import "../../../../@core/scss/vue/libs/vue-flatpicker";
</style>
