<template>
  <b-overlay :show="loading">
    <b-row class="match-height">
      <!--  left column   -->
      <b-col
        xl="6"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <sales-pie-chart />
          </b-col>

          <b-col cols="12">
            <spend-vs-withdraw-by-country-table />
          </b-col>

          <b-col cols="12">
            <withdrawal-chart />
          </b-col>
        </b-row>
      </b-col>
      <!--  right column    -->
      <b-col
        xl="6"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <withdrawals-pie-chart />
          </b-col>
          <b-col cols="12">
            <funded-analysis />
          </b-col>
          <b-col cols="12">
            <top-funded />
          </b-col>
          <b-col cols="12">
            <recent-accounts-table-gql />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import {
  BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import SpendVsWithdrawByCountryTable
from '@/views/statistics/fin-statistics/componenets/SpendVsWithdrawByCountryTable.vue'
import TopFunded from '@/components/page/statistics/TopFunded.vue'
import WithdrawalChart from '@/components/page/statistics/WithdrawalChart.vue'
import SalesPieChart from '@/components/page/statistics/SalesPieChart.vue'
import WithdrawalsPieChart from '@/components/page/statistics/WithdrawalsPieChart.vue'
import RecentAccountsTableGql from '@/components/entity/account-gql/RecentAccountsTableGql.vue'
import FundedAnalysis from '@/views/home/widgets/FundedAnalysis.vue'

export default {
  components: {
    FundedAnalysis,
    RecentAccountsTableGql,
    WithdrawalsPieChart,
    SalesPieChart,
    WithdrawalChart,
    TopFunded,
    SpendVsWithdrawByCountryTable,
    BOverlay,
    BRow,
    BCol,
  },
  data() {
    return {
      loading: false,
    }
  },
}
</script>
