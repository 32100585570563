<template>
  <div>
    <h3 v-if="title">
      {{ title }}
    </h3>
    <span v-if="salesData && salesData.length === 0">No results</span>
    <b-row
      v-else-if="!!salesData"
    >
      <b-col
        xl="8"
        lg="8"
        md="8"
        sm="12"
      >
        <b-row>
          <b-col
            cols="12"
            class="m-1 border-2"
          >
            <h5>{{ chartName }} by account type:</h5>
            <vue-apex-charts
              ref="chartByType"
              height="450px"
              :options="salesByType.options"
              :series="salesByType.series"
            />
          </b-col>
          <b-col
            cols="12"
            class="m-1"
          >
            <h5>{{ chartName }} by product: {{ localFilter.accountType.toUpperCase() }}</h5>
            <vue-apex-charts
              ref="chartBySize"
              height="450px"
              :options="salesBySize.options"
              :series="salesBySize.series"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        xl="4"
        lg="4"
        md="4"
        sm="12"
      >
        <b-row>
          <b-col
            cols="12"
            class="m-1"
          >
            <b-form-row>
              <b-form-group label="Chart type:">
                <b-form-radio-group
                  v-model="localFilter.chartType"
                  :options="chartTypeOptions"
                />
              </b-form-group>
              <b-form-row>
                <b-form-group label="Account type:">
                  <b-form-radio-group
                    v-model="localFilter.accountType"
                    :options="accountTypes"
                    @input="localFilter.product = 'All'"
                  />
                </b-form-group>
                <b-form-group label="Account size:">
                  <b-form-radio-group
                    v-model="localFilter.product"
                    :options="productList"
                  />
                </b-form-group>
              </b-form-row>
            </b-form-row>
          </b-col>
          <b-col
            cols="12"
            class="m-1"
          >
            <h5>{{ chartName }} by platform: {{ localFilter.product === 'All' ? '' : localFilter.product }}</h5>
            <vue-apex-charts
              ref="chartByPlatform"
              height="300px"
              :options="salesByPlatform.options"
              :series="salesByPlatform.series"
            />
          </b-col>

          <b-col
            cols="12"
            class="m-1"
          >
            <h5>{{ chartName }} by drawdown: {{ localFilter.product === 'All' ? '' : localFilter.product }}</h5>
            <vue-apex-charts
              ref="chartByDD"
              height="300px"
              :options="salesByDrawdown.options"
              :series="salesByDrawdown.series"
            />
          </b-col>
          <b-col
            v-if="chartName==='Sales'"
            cols="12"
            class="m-1"
          >
            <h5>{{ chartName }} by Custom sizes: {{ localFilter.product === 'All' ? '' : localFilter.product }}</h5>
            <vue-apex-charts
              ref="chartByDD"
              height="300px"
              :options="customSalesByAccountSize.options"
              :series="customSalesByAccountSize.series"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BFormGroup, BFormRadioGroup, BFormRow, BRow,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import _groupBy from 'lodash/groupBy'
import { formatCurrency, formatNumber } from '@/plugins/formaters'

export default {
  components: {
    VueApexCharts, BCol, BRow, BFormGroup, BFormRadioGroup, BFormRow,
  },
  props: {
    salesData: Array,
    title: {
      type: String,
      default: null,
    },
    chartName: {
      type: String,
      default: 'Sales',
    },
  },
  data() {
    return {
      localFilter: {
        accountType: 'All',
        product: 'All',
        chartType: 'Sum',
      },
      chartTypeOptions: ['Sum', 'Count'],
    }
  },
  computed: {
    reduceBy() {
      return this.localFilter.chartType === 'Sum' ? 'total_sum' : 'total_count'
    },
    accountTypes() {
      const accountTypes = [...new Set(this.salesData.map(sale => sale.account_type_name))]
      accountTypes.unshift('All')
      return accountTypes
    },
    productList() {
      const productList = [...new Set(this.salesData.filter(sale => sale.account_type_name === this.localFilter.accountType).map(sale => sale.product_name))].sort()
      productList.unshift('All')

      return productList
    },
    salesByType() {
      const sortedSales = this.salesData
      sortedSales.sort((a, b) => (a.label > b.label ? -1 : 1))
      const salesByType = _groupBy(sortedSales, result => result.account_type_name)
      const series = []
      Object.keys(salesByType).forEach(type => {
        series.push(salesByType[type].map(item => Number(item[this.reduceBy])).reduce((prev, next) => prev + next))
      })

      return { series, options: this.chartOptions(Object.keys(salesByType), 'bottom', this.reduceBy === 'total_sum') }
    },
    salesBySize() {
      let sales = this.salesData

      if (this.localFilter.accountType !== 'All') sales = this.salesData.filter(sale => sale.account_type_name === this.localFilter.accountType)

      const salesBySize = _groupBy(sales, result => result.product_name)
      const series = []
      Object.keys(salesBySize).forEach(type => {
        series.push(salesBySize[type].map(item => Number(item[this.reduceBy])).reduce((prev, next) => prev + next))
      })

      return { series, options: this.chartOptions(Object.keys(salesBySize), 'bottom', this.reduceBy === 'total_sum', false) }
    },
    salesByPlatform() {
      let sales = this.salesData

      if (this.localFilter.accountType !== 'All') sales = this.salesData.filter(sale => sale.account_type_name === this.localFilter.accountType)

      if (this.localFilter.product !== 'All') sales = sales.filter(sale => sale.product_name === this.localFilter.product)

      const salesByPlatform = _groupBy(sales, result => result.platform)
      const series = []
      Object.keys(salesByPlatform).forEach(type => {
        series.push(salesByPlatform[type].map(item => Number(item[this.reduceBy])).reduce((prev, next) => prev + next))
      })

      return { series, options: this.chartOptions(Object.keys(salesByPlatform), 'bottom', this.reduceBy === 'total_sum') }
    },
    salesByDrawdown() {
      let sales = this.salesData

      if (this.localFilter.accountType !== 'All') sales = this.salesData.filter(sale => sale.account_type_name === this.localFilter.accountType)

      if (this.localFilter.product !== 'All') sales = sales.filter(sale => sale.product_name === this.localFilter.product)

      const salesByDD = _groupBy(sales, result => result.rule_max_total_drawdown)
      const series = []
      Object.keys(salesByDD).forEach(type => {
        series.push(salesByDD[type].map(item => Number(item[this.reduceBy])).reduce((prev, next) => prev + next))
      })

      return { series, options: this.chartOptions(Object.keys(salesByDD), 'bottom', this.reduceBy === 'total_sum') }
    },
    customSalesByAccountSize() {
      let sales = this.salesData
      if (this.localFilter.accountType !== 'All') sales = this.salesData.filter(sale => sale.account_type_name === this.localFilter.accountType)

      sales = sales.filter(sale => sale.product_name.includes('Custom'))

      const salesBySize = _groupBy(sales, result => result.deposit)
      const salesBySizeGroup = {}
      Object.keys(salesBySize).forEach(size => {
        let group = ''
        switch (true) {
          case size <= 25000: group = '0 - 25k'; break
          case size > 25000 && size <= 50000: group = '25k - 50k'; break
          case size > 50000 && size <= 75000: group = '50k - 75k'; break
          case size > 75000 && size <= 100000: group = '75k - 100k'; break
          case size > 100000 && size <= 200000: group = '100k - 200k'; break
          case size > 200000 && size <= 300000: group = '200k - 300k'; break
          case size > 300000: group = 'Over 300k'; break
          default: group = ''
        }

        if (Object.hasOwn(salesBySizeGroup, group)) {
          salesBySize[size].forEach(item => salesBySizeGroup[group].push(item))
        } else {
          salesBySizeGroup[group] = salesBySize[size]
        }
      })

      const series = []
      Object.keys(salesBySizeGroup).forEach(type => {
        series.push(salesBySizeGroup[type].map(item => Number(item[this.reduceBy])).reduce((prev, next) => prev + next))
      })

      return { series, options: this.chartOptions(Object.keys(salesBySizeGroup), 'bottom', this.reduceBy === 'total_sum') }
    },
  },
  methods: {
    chartOptions(labels, legendPosition = 'top', formatPrice = true, showLegend = true) {
      return {
        chart: {
          type: 'donut',
        },
        labels,
        tooltip: {
          y: {
            formatter(val) {
              return formatPrice ? formatCurrency(val, 'USD') : formatNumber(val)
            },
          },
        },
        theme: {
          mode: 'light',
          palette: 'palette6',
          monochrome: {
            enabled: false,
            color: '#255aee',
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
        legend: {
          show: showLegend,
          position: legendPosition,
          offsetX: 0,
          labels: {
            colors: '#b9b9c3',
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#b9b9c3',
                  formatter(val) {
                    return formatPrice ? formatCurrency(val, 'USD') : formatNumber(val)
                  },
                },
                total: {
                  show: true,
                  label: 'Total',
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#b9b9c3',
                  formatter(w) {
                    const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                    return formatPrice ? formatCurrency(total, 'USD') : formatNumber(total)
                  },
                },
              },
            },
          },
        },
      }
    },
  },
}
</script>
