<template>
  <div>
    <h3>Account number or email:</h3>
    <b-form-group
      class="m-1"
    >
      <b-input-group>
        <b-form-input
          id="filterInput"
          v-model="search"
          type="search"
          placeholder="Search by account number or email"
          @keyup.enter="withdrawalsFilter.search = search"
        />
        <b-input-group-append>
          <b-button
            variant="outline-info"
            @click="clearSearch"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            variant="outline-primary"
            :disabled="!search"
            @click="withdrawalsFilter.search = search"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <data-table
      ref="withdrawalsTable"
      :module-name="'withdrawalsStatisticsList'"
      :fields="fields"
      :filters="withdrawalsFilter"
      sort-by="withdrawal_datetime"
      :sort-desc="true"
    >
      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(account)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'account-detail', params: { id: data.item.account.account_id } }">
              {{ data.item.account.account_id }}
            </b-link>
            <risk-score-badge
              v-if="data.item.account.risk_score"
              class="mx-1"
              :score-results="data.item.account.risk_score_results"
              :score="data.item.account.risk_score"
            />
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.account.tags"
              :extras-tags="getAccountExtraTags(data.item.account)"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(current_balance)="data">
        <span v-if="!data.item.completed">{{ formatCurrency(data.item.account.balance - data.item.account.deposit, data.item.account.currency.label) }}</span>
        <span v-else>Withdrawal completed</span>
      </template>

      <template #cell(captured_balance)="data">
        {{ formatCurrency(data.item.amount, data.item.account.currency.label) }}
      </template>

      <template #cell(amount)="data">
        {{ formatCurrency(data.item.profit_share_amount, data.item.account.currency.label) }}
      </template>

      <template #cell(profit_share)="data">
        {{ data.item.profit_share }}%
      </template>

      <template #cell(withdrawal_datetime)="data">
        {{ formatDateTime(data.item.withdrawal_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge
          :variant="`light-${withdrawalStateVariants[data.item.state]}`"
        >
          {{ data.item.state }}
        </b-badge>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(confirmed_at)="data">
        {{ formatDateTime(data.item.confirmed_at) }}
      </template>
    </data-table>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCol,
  BRow,
  BBadge,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { ACCOUNTS_SHORT, ACCOUNT_PHASES } from '@/enums/accounts'
import { STATE_VARIANTS } from '@/enums/withdrawals'
import DataTable from '@/components/ui/tables/DataTable.vue'
import TagsList from '@/components/page/TagsList.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'

const fields = [
  {
    key: 'user',
    sortable: false,
  },
  {
    key: 'account',
    sortable: false,
  },
  {
    key: 'current_balance',
    label: 'Current balance on account',
    sortable: true,
  },
  {
    key: 'captured_balance',
    label: 'Captured balance',
    sortable: true,
  },
  {
    key: 'amount',
    label: 'Profit share $',
    sortable: true,
  },
  {
    key: 'profit_share',
    label: 'Profit share %',
    sortable: true,
  },
  {
    key: 'withdrawal_datetime',
    sortable: true,
  },
  {
    key: 'state',
    sortable: false,
  },
  {
    key: 'confirmed_at',
    sortable: true,
  },
  {
    key: 'createdAt',
    sortable: true,
  },
]

export default {
  components: {
    DataTable,
    TagsList,
    RiskScoreBadge,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCol,
    BRow,
    BBadge,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    withdrawalsFilter: Object,
  },
  data() {
    return {
      accountTypes: ACCOUNTS_SHORT,
      accountSubtypes: ACCOUNT_PHASES,
      withdrawalStateVariants: STATE_VARIANTS,
      fields,
      search: '',
      title: 'Create withdrawal',
      withdrawalEdit: null,
      userTags: [],
      accountTags: [],
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    getAccountExtraTags,
    clearSearch() {
      this.withdrawalsFilter.search = ''
      this.search = ''
    },
  },
}
</script>
