<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          v-if="showAdvanceFilters"
          lg="3"
          class="mb-2"
        >
          <h3>Account type:</h3>
          <treeselect
            v-model="advancedFilter.account_type"
            :multiple="true"
            value-consists-of="LEAF_PRIORITY"
            :options="accountTypesOptions"
          />

          <h3
            v-if="advancedFilter.hasOwnProperty('country')"
            class="mt-1"
          >
            Country
          </h3>
          <country-select
            v-if="advancedFilter.hasOwnProperty('country')"
            v-model="advancedFilter.country"
          />
        </b-col>
        <b-col
          v-if="showAdvanceFilters"
          lg="2"
          class="mb-2"
        >
          <h3>Tags:</h3>
          <tag-tree-select v-model="advancedFilter.tag" />

          <h3
            v-if="useUserTags"
            class="mt-1"
          >
            User tags:
          </h3>
          <tag-tree-select
            v-if="useUserTags"
            v-model="advancedFilter.userTags"
          />
        </b-col>
        <b-col
          lg="3"
          class="mb-2"
        >
          <h3>Account number:</h3>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                placeholder="Search by account number or email"
                @keyup.enter="advancedFilter.search = search"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="clearSearch"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  :disabled="!search"
                  @click="advancedFilter.search = search"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="showAdvanceFilters"
          lg="2"
          class="mb-2"
        >
          <h3>States:</h3>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="advancedFilter.state"
            name="flavour-1"
            class="d-flex flex-wrap"
          >
            <b-badge
              v-for="(value, key, index) in stateVariants"
              :key="index"
              :variant="`light-${value}`"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                :value="key"
                class="vh-center"
              >
                <span :class="`text-${value}`">{{ key }}</span>
              </b-form-checkbox>
            </b-badge>
          </b-form-checkbox-group>
        </b-col>
        <b-col
          v-if="showAdvanceFilters"
        >
          <b-button-toolbar>
            <b-button-group>
              <b-button
                v-b-modal.tagsModal
                variant="primary"
              >
                Assign tags to accounts
              </b-button>
              <b-button
                v-b-modal.deleteMultipleModal
                variant="info"
              >
                Delete accounts
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
        <b-col
          v-if="advancedFilter.userId"
        >
          <b-button
            variant="outline-primary"
            class="btn-icon float-right mb-auto mr-2"
            @click="onCreateAccount"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-button
        v-if="showAdvanceFilters"
        variant="secondary"
        size="sm"
        class="mt-1"
        @click="rulesFilter = !rulesFilter"
      >
        Toggle rules filter
      </b-button>
      <b-row v-if="rulesFilter && showAdvanceFilters">
        <b-col
          v-for="(label, rule) in rules"
          class="col-12 col-lg"
        >
          <b-form-group
            class="mb-0"
            :label="label"
          >
            <b-input-group>
              <b-form-input
                v-model="advancedFilter[rule]"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <data-table
      ref="accountsTable"
      :module-name="'accountsList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="actions"
      deletable
      @detail="onDetail"
      @edit="onEdit"
      @disable="onDisable"
      @enable="onEnable"
      @removeCertificate="onRemoveCertificate"
      @view="onViewContractDetail"
      @forceComplete="onForceComplete"
      @accountReset="onAccountReset"
      @duplicate="onDuplicate"
      @copy="onCopy"
      @aventus="onAventus"
    >
      <template #cell(show_details)="row">
        <show-details :row="row" />
      </template>

      <template #row-details="row">
        <row-detail :row="row" />
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(account_type_name)="data">
        {{ accountTypes[data.item.account_type_name] }}
      </template>

      <template #cell(account_subtype_name)="data">
        {{ accountSubtypes[data.item.account_type_name][data.item.account_subtype_name] }}
      </template>

      <template #cell(rules)="data">
        {{ data.item.rule_max_daily_drawdown }}%|{{ data.item.rule_max_total_drawdown }}%|{{ data.item.rule_min_trading_days }}|{{ data.item.rule_first_withdrawal_after }}|{{ data.item.rule_withdrawal_after }}
      </template>

      <template #cell(tags)="data">
        <tags-list
          :tags="data.item.tags"
          :extras-tags="getAccountExtraTags(data.item)"
        />
      </template>

      <template #cell(account_id)="data">
        <b-link :to="{ name: 'account-detail', params: { id: data.item.account_id } }">
          {{ data.item.account_id }}
        </b-link>
        <risk-score-badge
          v-if="data.item.risk_score"
          class="mx-1"
          :score-results="data.item.risk_score_results"
          :score="data.item.risk_score"
        />

        <b-link
          :to="{ name: 'tools-risk-score', params: { id: data.item.account_id } }"
          target="_blank"
        >
          <b-button
            v-b-tooltip.hover.top="'Open risk score analysis'"
            class="m-50"
            variant="outline-primary"
            size="sm"
          >
            RS
          </b-button>
        </b-link>
      </template>

      <template #cell(parent_id)="data">
        <b-link
          v-if="data.item.parent.account_id"
          :to="{ name: 'account-detail', params: { id: data.item.parent.account_id } }"
        >
          {{ data.item.parent.account_id }}
        </b-link>
      </template>

      <template #cell(order_id)="data">
        <b-link
          v-if="findOrderNumber(data.item)"
          :to="{ name: 'order-detail', params: { id: findOrderNumber(data.item) } }"
        >
          {{ findOrderNumber(data.item) }}
        </b-link>
      </template>

      <template #cell(balance)="data">
        <b-badge
          :variant="data.item.balance>data.item.deposit ? 'success' : 'danger'"
        >
          {{ formatCurrency(data.item.balance, data.item.currency.label) }}
        </b-badge>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.deposit, data.item.currency.label) }}
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(start_datetime)="data">
        {{ formatDateTime(data.item.start_datetime) }}
      </template>

      <template #cell(end_datetime)="data">
        {{ formatDateTime(data.item.end_datetime) }}
      </template>

      <template #cell(close_datetime)="data">
        {{ formatDateTime(data.item.close_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
      </template>

    </data-table>
    <b-modal
      id="accountModal"
      title="Edit Account"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <account-form
        module-name="accountsList"
        :account="accountEdit"
        @submit="onEditSubmit()"
      />
    </b-modal>
    <b-modal
      id="resetModal"
      title="Reset Account"
      :no-close-on-backdrop="true"
      hide-footer
      size="xl"
    >
      <account-reset-form
        :account-id="accountToView"
        @submit="onAccountResetSubmit"
      />
    </b-modal>
    <b-modal
      id="createModal"
      title="Create Account"
      :no-close-on-backdrop="true"
      size="xl"
      hide-footer
    >
      <new-account :user="this.$parent.user" />
    </b-modal>
    <b-modal
      id="tagsModal"
      title="Assign tags to accounts"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <multiple-tag-form
        items-name="Accounts ids"
        :is-busy="tagsToAccountsLoading"
        show-assign-to-users-btn
        @submit="onSubmitMultipleTags"
      />
    </b-modal>
    <b-modal
      id="deleteMultipleModal"
      title="Delete accounts by account number (separated by ',')"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <text-collection-form
        items-name="Accounts ids"
        :is-busy="accountsDeleteLoading"
        @submit="onSubmitDeleteMultiple"
      />
    </b-modal>
    <b-modal
      id="contractDataModal"
      title="Data"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
      @close="onCloseContractDetail"
    >
      <contract-data-preview :account-id="accountToView" />
    </b-modal>
    <b-modal
      id="accountCopyModal"
      title="Copy account"
      :no-close-on-backdrop="true"
      hide-footer
      @close="onCloseCopyModal(false)"
    >
      <account-copy-form
        :account="accountToCopy"
        @close="onCloseCopyModal(true)"
      />
    </b-modal>
  </div>
</template>

<script>
import { dispatch, get } from 'vuex-pathify'
import {
  BButtonToolbar,
  BButtonGroup,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCol,
  BRow,
  BBadge,
  BCard,
  BFormCheckbox,
  BFormCheckboxGroup,
  BLink,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import ShowDetails from '@/components/ui/tables/ShowDetails.vue'
import table from '@/mixins/table'
import { ACCOUNTS_SHORT, ACCOUNT_PHASES } from '@/enums/accounts'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import accountTypes from './components/accountTypes'
import RowDetail from './components/RowDetail.vue'
import DataTable from '@/components/ui/tables/DataTable.vue'
import AccountForm from '@/components/forms/account/AccountForm.vue'
import TagsList from '@/components/page/TagsList.vue'
import NewAccount from '@/views/account/new/NewAccount.vue'
import MultipleTagForm from '@/components/forms/tag/MutipleTagForm.vue'
import TextCollectionForm from '@/components/forms/TextCollectionForm.vue'
import AccountAPI from '@/api/account'
import TagTreeSelect from '@/components/ui/tables/TagTreeSelect.vue'
import ContractDataPreview from '@/components/page/contract-veriff/ContractDataPreview.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import CountrySelect from '@/components/ui/forms/CountrySelect.vue'
import AccountResetForm from '@/components/forms/account/AccountResetForm.vue'
import AccountCopyForm from '@/components/forms/account/AccountCopyForm.vue'

const actions = [
  {
    text: 'Account details',
    icon: 'SearchIcon',
    emit: 'detail',
  },
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Duplicate account to phase 1',
    icon: 'CopyIcon',
    emit: 'duplicate',
  },
  {
    text: 'Copy account',
    icon: 'CopyIcon',
    emit: 'copy',
  },
  {
    text: 'Account reset',
    icon: 'RepeatIcon',
    emit: 'accountReset',
  },
  {
    text: 'View contract detail',
    icon: 'EyeIcon',
    emit: 'view',
  },
  {
    text: 'Disable account',
    icon: 'DeleteIcon',
    emit: 'disable',
  },
  {
    text: 'Enable account',
    icon: 'CheckCircleIcon',
    emit: 'enable',
  },
  {
    text: 'Force complete account',
    icon: 'CheckCircleIcon',
    emit: 'forceComplete',
  },
  {
    text: 'Remove certificate',
    icon: 'TrashIcon',
    emit: 'removeCertificate',
  },
  {
    text: 'AVENTUS',
    icon: 'RepeatIcon',
    emit: 'aventus',
  },
]

const fields = [
  'show_details',
  {
    key: 'user',
    sortable: false,
  },
  {
    key: 'account_type_name',
    sortable: true,
  },
  {
    key: 'account_subtype_name',
    sortable: true,
  },
  {
    key: 'rules',
    sortable: true,
    label: 'ID|DD|TD|FW|W',
  },
  {
    key: 'tags',
    sortable: false,
  },
  {
    key: 'account_id',
    sortable: false,
  },
  {
    key: 'parent_id',
    sortable: false,
  },
  {
    key: 'order_id',
    sortable: false,
  },
  {
    key: 'deposit',
    sortable: false,
  },
  {
    key: 'balance',
    sortable: false,
  },
  {
    key: 'createdAt',
    sortable: true,
  },
  {
    key: 'start_datetime',
    sortable: true,
  },
  {
    key: 'end_datetime',
    sortable: true,
  },
  {
    key: 'close_datetime',
    sortable: true,
  },
  {
    key: 'state',
    sortable: false,
  },
  {
    key: 'actions',
    sortable: false,
  },
]

const ACCOUNT_RULES = {
  rule_max_days: 'Max days',
  rule_max_daily_drawdown: 'Max daily DD',
  rule_max_total_drawdown: 'Max initial DD',
  rule_min_trading_days: 'Min trading days',
  rule_min_profit: 'Min profit',
  rule_first_withdrawal_after: 'First withdrawal',
  rule_withdrawal_after: 'Withdrawal',
}

export default {
  components: {
    AccountCopyForm,
    CountrySelect,
    RiskScoreBadge,
    ContractDataPreview,
    TagTreeSelect,
    NewAccount,
    AccountForm,
    DataTable,
    ShowDetails,
    TagsList,
    MultipleTagForm,
    TextCollectionForm,
    AccountResetForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCol,
    BRow,
    BBadge,
    Treeselect,
    BCard,
    BFormCheckbox,
    BFormCheckboxGroup,
    RowDetail,
    BLink,
    BModal,
    BButtonToolbar,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [table],
  props: {
    defaultFilter: {
      type: Object,
      default: null,
    },
    useUserTags: {
      type: Boolean,
      default: false,
    },
    showAdvanceFilters: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      stateVariants: {
        active: 'primary',
        failed: 'danger',
        completed: 'success',
      },
      rulesFilter: false,
      rules: ACCOUNT_RULES,
      accountTypes: ACCOUNTS_SHORT,
      accountSubtypes: ACCOUNT_PHASES,
      fields,
      advancedFilter: {
        account_type: [],
        state: [],
        search: '',
        tag: [],
        userTags: [],
        country: null,
      },
      accountTypesOptions: accountTypes,
      actions,
      accountEdit: null,
      tagsToAccountsLoading: false,
      accountsDeleteLoading: false,
      accountToView: null,
      accountToCopy: null,
      search: null,
    }
  },
  computed: {
    ...get('accountsList', ['filters']),
  },
  beforeMount() {
    if (this.defaultFilter) this.advancedFilter = { ...this.defaultFilter }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    getAccountExtraTags,
    onCreateAccount() {
      this.$bvModal.show('createModal')
    },
    onEdit(item) {
      dispatch('accountsList/get', item.account_id).then(data => {
        this.accountEdit = data.account_data
        this.$bvModal.show('accountModal')
      })
    },
    onViewContractDetail(item) {
      this.accountToView = item.account_id
      this.$bvModal.show('contractDataModal')
    },
    onCloseContractDetail() {
      this.accountToView = null
    },
    onEditSubmit() {
      this.$bvModal.hide('accountModal')
    },
    onAccountReset(item) {
      this.accountToView = item.id
      this.$bvModal.show('resetModal')
    },
    onAccountResetSubmit() {
      this.$bvModal.hide('resetModal')
    },
    onDetail(item) {
      this.$router.push({ name: 'account-detail', params: { id: item.account_id } })
    },
    onForceComplete(item) {
      if (item.state === 'completed') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Account is completed already!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be marked as complete`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, force complete',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.forceCompleteAccount(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was completed successfully',
                icon: 'WarningIcon',
                text: 'Account is complete now!',
                variant: 'success',
              },
            })
            this.$refs.accountsTable.reloadAll()
          })
        }
      })
    },
    onDisable(item) {
      if (item.state === 'failed' && !item.is_active) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Account is disabled already!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be disabled and set as failed`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, disable',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.disableAccount(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was disable successfully',
                icon: 'WarningIcon',
                text: 'Account is disabled now!',
                variant: 'success',
              },
            })
            this.$refs.accountsTable.reloadAll()
          })
        }
      })
    },
    onDuplicate(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be duplicated to phase 1`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, duplicate',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.duplicateAccount(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was duplicated successfully',
                icon: 'WarningIcon',
                text: 'Account is duplicated now!',
                variant: 'success',
              },
            })
            this.$refs.accountsTable.reloadAll()
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Action failed.',
                icon: 'WarningIcon',
                text: 'Account duplication failed!',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    onCopy(item) {
      if (item.account_subtype_name === 'funded') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Action failed.',
            icon: 'WarningIcon',
            text: 'You can not copy FUNDED account!',
            variant: 'danger',
          },
        })
        return
      }

      this.accountToCopy = item
      this.$bvModal.show('accountCopyModal')
    },
    onCloseCopyModal(reloadTable = false) {
      this.$bvModal.hide('accountCopyModal')
      this.accountToCopy = null
      if (reloadTable) this.$refs.accountsTable.reloadAll()
    },
    onAventus(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be checked by AVENTUS`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, run analysis',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.requestAventus(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was add to the front successfully',
                icon: 'WarningIcon',
                text: 'Account is waiting for test now!',
                variant: 'success',
              },
            })
            this.$refs.accountsTable.reloadAll()
          })
        }
      })
    },
    onEnable(item) {
      if (item.state === 'active' && item.is_active) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Account is active already!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be enabled and set as active`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, enable',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.enableAccount(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was enabled successfully',
                icon: 'WarningIcon',
                text: 'Account is activated now!',
                variant: 'success',
              },
            })
            this.$refs.accountsTable.reloadAll()
          })
        }
      })
    },
    onSubmitMultipleTags(data) {
      this.tagsToAccountsLoading = true
      AccountAPI.postTagsToAccounts(data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tags was assigned to accounts',
              icon: 'SendIcon',
              text: 'Tags was successfully assigned to accounts.',
              variant: 'success',
            },
          })
          this.$bvModal.hide('tagsModal')
        })
        .catch(error => {
          if (error.status === 400) {
            Swal.fire({
              title: 'Some tags was not assign to accounts',
              text: error.data.detail,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            })
          }
        })
        .finally(() => {
          this.$refs.accountsTable.reloadAll()
          this.tagsToAccountsLoading = false
        })
    },
    onSubmitDeleteMultiple(items) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Accounts will be permanently deleted!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then(result => {
        if (result.isConfirmed) {
          this.accountsDeleteLoading = true
          AccountAPI.deleteAccounts(items)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'All accounts was removed.',
                  icon: 'SendIcon',
                  text: 'All accounts was removed.',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('deleteMultipleModal')
            })
            .catch(error => {
              if (error.status === 400) {
                Swal.fire({
                  title: 'This accounts can not be removed: ',
                  text: error.data.detail,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Ok',
                })
              }
            })
            .finally(() => {
              this.$refs.accountsTable.reloadAll()
              this.accountsDeleteLoading = false
            })
        }
      })
    },
    onRemoveCertificate(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Funded certificate will be remove',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.removeFundedCertificate(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Certificate was removed',
                icon: 'TrashIcon',
                text: '',
                variant: 'success',
              },
            })
          })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'TrashIcon',
                  text: error.data.detail,
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    findOrderNumber(account) {
      if (account?.parent?.parent?.order) return account.parent.parent.order.order_number
      if (account?.parent?.order) return account.parent.order.order_number
      if (account?.order) return account.order.order_number
      return null
    },
    clearSearch() {
      this.defaultFilter.search = ''
      this.search = ''
    },
  },
}
</script>
