<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        Sales chart
      </b-card-title>
      <div class="d-flex flex-wrap align-items-top">
        <b-form-group class="mt-1 mx-1 pl-5">
          <h5 class="mr-5">
            Country
          </h5>
          <country-select
            v-model="advancedFilter.country"
          />
        </b-form-group>

        <b-form-group class="mt-1 mx-1">
          <h5>
            Date range
          </h5>
          <div class="d-flex">
            <flat-pickr
              v-model="dateRange"
              class="form-control"
              :config="pickrConfig"
            />
            <b-button
              v-if="!showCompare"
              variant="outline-primary"
              :disabled="dateRange == null"
              @click.prevent="load(false)"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>

          <b-form-checkbox
            v-model="showCompare"
            class="m-50"
          >
            Add a new range selector
          </b-form-checkbox>
          <b-form-checkbox
            v-model="useSingleDate"
            class="m-50"
            @change="onChangeSingleDate"
          >
            Select single date
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="showCompare"
          class="mt-1 mx-1"
        >
          <h5>
            New date range
          </h5>
          <div class="d-flex">
            <flat-pickr
              v-model="dateRangeCompare"
              class="form-control ml-1"
              :config="pickrConfig"
            />
            <b-button
              variant="outline-primary"
              :disabled="dateRange == null"
              @click.prevent="reload(false)"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </b-form-group>

        <b-button
          v-b-tooltip.hover.top="'Force reload statistics'"
          class="my-auto mx-1 align-self-center"
          variant="outline-primary"
          :disabled="dateRange === null"
          @click.prevent="reload(true)"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </div>
    </b-card-header>
    <b-card-body>
      <b-overlay :show="loading">
        <sales-pie-chart-render
          :sales-data="salesData"
        />
        <hr>
        <sales-pie-chart-render
          v-if="salesDataCompare && showCompare"
          :sales-data="salesDataCompare"
          :title="`Sales from ${dateRangeCompare}`"
        />
      </b-overlay>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay, BFormCheckbox,
  BFormGroup, BButton, VBTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { get } from 'vuex-pathify'
import StatisticsAPI from '@/api/statistics'
import CountrySelect from '@/components/ui/forms/CountrySelect.vue'
import SalesPieChartRender from '@/components/page/statistics/components/SalesPieChartRender.vue'

export default {
  components: {
    SalesPieChartRender,
    CountrySelect,
    flatPickr,
    BButton,
    BFormGroup,
    BOverlay,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCard,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      showCompare: false,
      useSingleDate: false,
      salesData: null,
      salesDataCompare: null,
      advancedFilter: {
        country: null,
        from: null,
        to: null,
        fromCompare: null,
        toCompare: null,
      },
      dateRange: null,
      dateRangeCompare: null,
      loading: false,
    }
  },
  computed: {
    pickrConfig() {
      if (!this.me.roles.includes('ROLE_ADMIN_EXTERNAL')) return { wrap: true, mode: this.useSingleDate ? 'single' : 'range', dateFormat: 'Y-m-d' }

      const back4M = new Date()
      back4M.setDate(back4M.getDate() - (28 * 4))

      return {
        wrap: true, mode: this.useSingleDate ? 'single' : 'range', dateFormat: 'Y-m-d', minDate: back4M,
      }
    },
    ...get('auth', ['me']),
  },
  beforeMount() {
    const now = new Date()
    const back7 = new Date()

    back7.setDate(back7.getDate() - 28)

    this.dateRange = `${back7.toISOString().slice(0, 10)} to ${now.toISOString().slice(0, 10)}`
    this.load(false)
  },
  methods: {
    load(force = false) {
      if (this.dateRange === null) return

      this.loading = true

      if (this.useSingleDate) {
        this.advancedFilter.from = this.dateRange.concat(' 00:00:00')
        this.advancedFilter.to = this.dateRange.concat(' 23:59:59')
      } else {
        const date = this.dateRange.split(' to ')
        this.advancedFilter.from = date[0].concat(' 00:00:00')
        this.advancedFilter.to = date[1].concat(' 23:59:59')
      }

      StatisticsAPI.getSales(this.advancedFilter.from, this.advancedFilter.to, this.advancedFilter.country, force).then(response => {
        this.salesData = response
      }).finally(() => {
        this.loading = false
      })
    },
    loadCompare(force = false) {
      if (this.dateRangeCompare === null) return

      this.loading = true

      if (this.useSingleDate) {
        this.advancedFilter.fromCompare = this.dateRangeCompare.concat(' 00:00:00')
        this.advancedFilter.toCompare = this.dateRangeCompare.concat(' 23:59:59')
      } else {
        const date = this.dateRangeCompare.split(' to ')
        this.advancedFilter.fromCompare = date[0].concat(' 00:00:00')
        this.advancedFilter.toCompare = date[1].concat(' 23:59:59')
      }

      StatisticsAPI.getSales(this.advancedFilter.fromCompare, this.advancedFilter.toCompare, this.advancedFilter.country, force).then(response => {
        this.salesDataCompare = response
      }).finally(() => {
        this.loading = false
      })
    },
    reload(force) {
      this.load(force)
      this.loadCompare(force)
    },
    onChangeSingleDate() {
      this.dateRange = null
      this.dateRangeCompare = null
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker";
</style>
